var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.isNotLoggedIn === true
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-column justify-center align-center fill-height"
        },
        [
          _c(
            "v-card",
            {
              staticClass: "elevation-0",
              staticStyle: {
                "box-shadow": "rgb(180, 180, 202) 0px 0px 20px 2px !important",
                "border-radius": "5px 5px 0 0"
              },
              attrs: { "max-width": "300" }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "py-1",
                  staticStyle: { background: "rgba(241,241,241,0.8)" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c("img", {
                        staticClass: "mt-2",
                        attrs: {
                          width: "58",
                          src: require("@/assets/N-Nuvolos-blue.svg")
                        }
                      }),
                      _c("span", { staticClass: "title pt-2 primary--text" }, [
                        _vm._v("Nuvolos")
                      ])
                    ]
                  )
                ]
              ),
              _c("v-card-text", { staticClass: "font-weight-light" }, [
                _vm.loginStep === 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-wrap justify-center align-center mt-2 black--text"
                      },
                      [
                        _c(
                          "v-form",
                          {
                            staticClass: "mt-6",
                            staticStyle: { width: "100%" },
                            attrs: { onSubmit: "return false;" },
                            on: {
                              submit: function($event) {
                                return _vm.checkLoginType(_vm.email)
                              }
                            },
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "font-weight-light",
                              staticStyle: { "font-size": "13px" },
                              attrs: {
                                placeholder: "Nuvolos Sign-up Email",
                                id: "nv-email",
                                rules: [_vm.rules.nonEmptyUsername],
                                autofocus: "",
                                outlined: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            }),
                            _vm.checkLoginTypeError
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { text: "", type: "error" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Error")]
                                        ),
                                        _vm._v(
                                          " We encountered an error. Please check the system status or reach out to us using the button below. "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "text-decoration-underline",
                                            attrs: {
                                              light: "",
                                              outlined: "",
                                              color: "red"
                                            },
                                            on: { click: _vm.showIntercom }
                                          },
                                          [_vm._v("Help")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.loginStep === 0
                  ? _c("div", [
                      _c("div", { staticClass: "mb-2 text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "black--text",
                            staticStyle: {
                              "text-decoration": "none",
                              "font-size": "13px"
                            },
                            on: { click: _vm.getEmailHelp }
                          },
                          [_vm._v("Don't remember your sign-up e-mail?")]
                        )
                      ]),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "black--text",
                            staticStyle: {
                              "text-decoration": "none",
                              "font-size": "13px"
                            },
                            on: { click: _vm.getAccountHelp }
                          },
                          [_vm._v("Don't have an account?")]
                        )
                      ]),
                      _vm.showEmailHelp
                        ? _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _vm.failedEmail
                                ? _c("v-alert", { attrs: { type: "error" } }, [
                                    _vm._v(
                                      " No account found for " +
                                        _vm._s(_vm.failedEmail) +
                                        ". "
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-4",
                                  attrs: { type: "info" }
                                },
                                [
                                  _vm._v(
                                    " Please check the invitation e-mail you received from Nuvolos and use the e-mail address it was sent to. "
                                  )
                                ]
                              ),
                              _vm._v(
                                " You might be able to log in directly using your institution: "
                              ),
                              _c("institution-login", {
                                staticClass: "my-2",
                                attrs: { redirectTo: _vm.nvTarget }
                              }),
                              _c("div", { staticClass: "caption" }, [
                                _vm._v(
                                  "If you have trouble accessing your account, you can "
                                ),
                                _c("a", { on: { click: _vm.askForHelp } }, [
                                  _vm._v("ask for help")
                                ]),
                                _vm._v(".")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showAccountHelp
                        ? _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c("v-alert", { attrs: { type: "info" } }, [
                                _vm._v(
                                  " Students accessing Nuvolos require an invitation from their course administrator to create an account. "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _c(
                "v-card-actions",
                { staticClass: "py-0 px-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-light",
                      staticStyle: { height: "70px" },
                      attrs: {
                        color: "secondary",
                        type: "submit",
                        block: "",
                        disabled: !_vm.valid,
                        tile: "",
                        loading: _vm.checkingLoginMethod
                      },
                      on: {
                        click: function($event) {
                          return _vm.checkLoginType(_vm.email)
                        }
                      }
                    },
                    [
                      _vm._v("Next "),
                      _c("v-icon", [_vm._v("keyboard_arrow_right")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("v-container", { attrs: { "fill-height": "", "text-center": "" } }, [
        _c(
          "div",
          { staticClass: "d-flex align-center justify-center loadingClass" },
          [
            _c("v-progress-circular", {
              attrs: {
                indeterminate: "",
                color: "secondary",
                size: "64",
                width: "6"
              }
            })
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }